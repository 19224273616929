<template>
  <div>
    <v-row class="mx-auto">
      <v-col cols="12">
        <upload-alert 
          :allowedFiles="allowedFiles"
          :allowedEncoding="allowedEncoding"
        >
          <template v-slot:upload-alert-extra-information>
            <slot name="upload-alert-extra-information"></slot>
          </template>
        </upload-alert>
      </v-col>
    </v-row>
    <v-row class="mx-auto">
      <v-col cols="12">
        <upload-area @files:change="filesChange" />
      </v-col>
    </v-row>
    <v-row class="mx-auto">
      <v-col cols="12">
        <general-progress-bars
          class="mt-11 mb-11"
          :items="uploadProgressData"
          prefixText="Enviando"
          suffixProperty="name"
          :includeHashOnText="false"
          :truncateText="true"
        />
        <upload-queue
          :files.sync="files"
          :modulo10_antigo="modulo10_antigo"
          :uploadProgressData.sync="uploadProgressData"
          @file:uploaded="handleFileUploaded"
          :dispatcherFile="dispatcherFile"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'UploadFiles',
  components: {
    UploadAlert: () =>
      import('@/components/general/UploadFiles/UploadAlert.vue'),
    UploadArea: () => import('@/components/general/UploadFiles/UploadArea.vue'),
    UploadQueue: () =>
      import('@/components/general/UploadFiles/UploadQueue.vue'),
    GeneralProgressBars: () =>
      import('@/components/general/GeneralProgressBars.vue')
  },
  props: {
    allowedFiles: {
      type: Array,
      default: () => []
    },
    allowedEncoding: {
      type: Array,
      default: () => []
    },
    dispatcherFile: {
      type: String,
      default: null
    },
    modulo10_antigo: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      files: [],
      uploadProgressData: {},
      uploadedFiles: []
    };
  },
  methods: {
    filesChange(file) {
      this.files.push(file);
    },
    handleFileUploaded(event) {
      const { files } = event;
      files.forEach((file) => this.uploadedFiles.push(file));
      this.$emit('file:uploaded', event);
    }
  }
};
</script>
